import React from "react";
import { Wrapper, PageButton } from "./style";

export const Pagination = ({
  totalPages,
  activePage,
  pageButtonDestination,
}) => {
  return (
    <Wrapper>
      {Array.from({ length: totalPages }).map((_, i) => (
        <PageButton
          key={i}
          $isCurrentPage={i + 1 === activePage}
          to={pageButtonDestination(i)}
        >
          {i + 1}
        </PageButton>
      ))}
    </Wrapper>
  );
};
