import styled from "styled-components";
import { Link } from "../Link";

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const PageButton = styled(Link)`
  color: ${(p) => (p.$isCurrentPage ? p.theme.borderColor : p.theme.red)};
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.borderColor};
`;
